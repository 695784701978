import firebase from "firebase/app";
import "firebase/auth";

import parseError from "./parseError";

export default async (_, data, context) => {
    return await firebase
        .auth()
        .signInWithEmailAndPassword(data.email.toLowerCase(), data.password)
        .then(() => true)
        .catch(function(err) {
            console.log("HIT ERROR!");
            return parseError(err);
        });
};
