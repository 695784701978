import gql from "graphql-tag";

export default gql`
    query getUser($uid: String!) {
        user(uid: $uid) {
            id
            uid
            active
            name
            picture
            cover
            email
            role
            address
            loggedIn
            created
            updated
        }
        loading @client
        saver @client
        messages @client {
            id
            text
            type
        }
        loggedIn @client
        myUID @client
        currentRoute @client
    }
`;
