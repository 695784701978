import gql from "graphql-tag";

export default gql`
    query getLocal {
        loading @client
        saver @client
        messages @client {
            id
            text
            type
        }
        loggedIn @client
        myUID @client
        currentRoute @client
    }
`;
