import firebase from "firebase/app";
import "firebase/auth";

export default async (_, __, context) => {
    firebase.auth().onAuthStateChanged(async user => {
        if (user)
            context.client.writeData({
                data: { myId: user.id || null, myUID: user.uid, loggedIn: true, loading: false }
            });
        else context.client.writeData({ data: { loggedIn: false, loading: false } });
    });
    return;
};
