import { gql } from "@apollo/client";

// Helper functions
import * as permissions from "../../functions/permissions";

// Handles navigation of browser
const baseQuery = gql`
    query getUser {
        myUID
        loggedIn
        routeHistory
    }
`;

export default async (_, data, context) => {
    console.log("RUNNING ROUTE!");

    var { routeHistory } = await context.cache.readQuery({ query: baseQuery });

    routeHistory = JSON.parse(JSON.stringify(routeHistory));
    var currentRoute = document.location.href;

    if (routeHistory.length < 2) routeHistory.push(currentRoute);
    else if (routeHistory[routeHistory.length - 2] !== currentRoute) routeHistory.push(currentRoute);
    else routeHistory.splice(routeHistory.length - 1, 1);

    context.client.writeData({ data: { routeHistory, currentRoute } });
};
