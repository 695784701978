import React from "react";
import { ApolloProvider, useQuery } from "@apollo/client";

import graphql from "../graphql/index";
import { Loading } from "ui-core";

// Layers
import MessagesLayer from "../components/_layers/Messages.react";
import OverlaysLayer from "../components/_layers/Overlays.react";

// Queries
import userQuery from "../queries/me";
import localQuery from "../queries/local";

// Pages
import pageMap from "../pagemap";

const client = graphql();

var load = props => {
    // Splits URL
    const myRoute = document.location.hash.replace("#", "").toLowerCase();
    var myRouteArray = myRoute.split("/");
    if (myRouteArray.length === 1 && myRouteArray[0] === "") myRouteArray = [];

    // Figures out target component
    var { target, currentPage } = find_component(props.localData, "components/", myRoute, myRouteArray, pageMap);

    // Checks if a redirect to child is needed
    if ([null, undefined, ""].indexOf(currentPage) === -1 && currentPage.redirectToChild) {
        window.location.href = document.location.href + "/" + currentPage.redirectToChild;
        return;
    }

    const TargetComponent = React.lazy(() => import("../" + target));

    return (
        <ApolloProvider client={client}>
            <Page id="base-page" key="full-page" {...props} component={TargetComponent} />
        </ApolloProvider>
    );
};

// Global page component that mounts the target page
const Page = props => {
    const loggedIn = props.localData.loggedIn;
    const TargetComponent = props.component;

    const { loading, data, refetch } = useQuery(loggedIn ? userQuery : localQuery, {
        variables: loggedIn ? { uid: props.localData.myUID } : {},
        pollInterval: 10000
    });

    if (loading && [null, undefined].indexOf(data) > -1) return <Loading />;

    const { user, ...localData } = data;

    return [
        <TargetComponent id="base" key="base" user={loggedIn ? user : null} userRefetch={refetch} localData={localData} />,
        <MessagesLayer id="messages" key="messages" localData={localData} />,
        <OverlaysLayer id="overlays" key="overlays" />
    ];
};

// Helps find the component in the file tree corresponding to the route
var find_component = (localData, target, route, routeArray, pages) => {
    // If not logged in, shows login screen
    if (localData.loggedIn !== true && route.indexOf("login") === -1) return { target: "components/login", currentPage: null };

    // If no route, shows default
    if (routeArray.length === 0) {
        var defaultPage = pages.find(item => item.default) || pages[0];
        return { target: target + defaultPage.value, currentPage: null };
    }

    // Runs round
    var single = routeArray.splice(0, 1)[0];
    var currentPage = pages.find(item => item.value === single);

    if (currentPage !== undefined) target += currentPage.value;
    else {
        currentPage = pages.find(item => item.value === ":id");
        target += ":id";
    }

    // Figures out if needs to go deeper or returns
    if (currentPage === undefined || currentPage.pages === undefined) return { target, currentPage };
    else if (routeArray.length === 0) return { target, currentPage };
    else return find_component(localData, target + "/", route, routeArray, currentPage.pages);
};

export { load };
