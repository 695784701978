import config from "../config.public";
import * as firebase from "firebase/app";

// Configures firebase
const fbConfig = {
    apiKey: config.gcf.prod.apiKey,
    authDomain: config.gcf.prod.authDomain,
    projectId: config.gcf.prod.projectId,
    databaseURL: config.gcf.prod.databaseURL,
    appId: config.gcf.prod.appId
};

firebase.initializeApp(fbConfig);
