import React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

const OverlayLayer = props => {
    return (
        <ReactCSSTransitionGroup
            id="overlays"
            key="overlay-transition-group"
            transitionName="overlay"
            transitionAppear={false}
            transitionLeave={true}
            transitionEnter={true}
            transitionEnterTimeout={50}
            transitionLeaveTimeout={100}
        ></ReactCSSTransitionGroup>
    );
};

export default OverlayLayer;
