import config from "../config.public";

var check = (userPermission, page) => {
    if (userPermission === undefined) return false;

    var j;
    for (var i = 0; i < config.permissions.length; i++) {
        for (j = 0; j < config.permissions[i].items.length; j++) {
            if (userPermission[config.permissions[i].items[j].value]) {
                if (config.permissions[i].items[j].page === page) return true;
                if (config.permissions[i].page === page) return true;
                if (config.permissions[i].items[j].value === page) return true;
            }
        }
    }

    return false;
};

var check_client = (User, page, clientId) => {
    if (User.role === "admin") return true;
    if (User.permissions === undefined) return false;

    for (var i = 0; i < User.permissions.length; i++) {
        if (User.permissions[i].clientId === clientId) return check(User.permissions[i], page);
    }

    return false;
};

var find = (clientId, userPermissions) => {
    if (!Array.isArray(userPermissions)) return null;

    for (var i = 0; i < userPermissions.length; i++) {
        if (userPermissions[i].clientId === clientId) return userPermissions[i];
    }

    return null;
};

/*
var find_page = (pages, target) => {
	var j, temp;
	for (var i = 0; i < config.pages.length; i++) {
		if (pages[i].value === target) return pages[i];
		else {
			if (pages[i].pages !== undefined) {
				for (j = 0; j < pages[i].pages.length; j++) {
					temp = find_page(pages[i].pages, target);
					if (temp !== false) return temp;
				}
            }
		}
	}

	return false;
};
*/

var global_check = (User, myPage) => {
    if (myPage.globalPermissions !== undefined) {
        for (var i = 0; i < myPage.globalPermissions.length; i++) {
            if (User[myPage.globalPermissions[i]] === true) return true;
        }
    }

    return false;
};

var full_check = (User, page, myPage, clientId) => {
    if (User.role === "admin") return true;
    if (global_check(User, myPage)) return true;
    if (User.permissions === undefined) return false;

    for (var i = 0; i < User.permissions.length; i++) {
        if (clientId !== undefined && check_client(User.permissions[i], clientId, page)) return true;
        else if (clientId === undefined && check(User.permissions[i], page)) return true;
    }

    return false;
};

export { check, check_client, find, full_check, global_check };
