import * as ui from "../resolvers/ui";
import * as users from "../resolvers/users";

export default {
    Mutation: {
        update: async (_, data, context) => {
            context.client.writeData({ data });
            return;
        },
        addMessage: ui.addMessage,
        removeMessage: ui.removeMessage,
        show: ui.show,
        monitor: ui.monitor,
        route: ui.route,
        goBack: ui.goBack,
        showOverlay: ui.showOverlay,
        saver: ui.saver,
        auth: users.auth,
        login: users.login,
        logout: users.logout,
        resetPassword: users.resetPassword
    },
    Query: {
        token: users.token
    }
};
