const config = {
    core: {
        name: "Sales App",
        logo: "/images/logo_white_bg@2x.png"
    },
    theme: {
        main: {
            primaryColor: "#7b1fa2",
            primaryLightColor: "#ae52d4",
            primaryDarkColor: "#4a0072",
            primaryTextColor: "white",
            primaryWarningColor: "#c62828",
            primaryWarningLightColor: "#ff5f52",
            textColor: "black",
            font: "Roboto"
        },
        appbar: {
            backgroundColor: "white",
            unselectedColor: "grey",
            textColor: "#7b1fa2",
            font: "Roboto"
        },
        loader: {
            background: "#ae52d4",
            foreground: "#4a0072"
        }
    },
    registration: {
        allowSignup: false,
        allowGoogle: false
    },
    pages: [],
    userMenu: [
        {
            text: "Settings",
            value: "settings"
        },
        {
            text: "Logout",
            value: "logout"
        }
    ],
    gcf: {
        prod: {
            apiKey: "AIzaSyBdSfr9zEippJ8294JXaC6XqOmgTMUMNUQ",
            authDomain: "hioperator-prod.firebaseapp.com",
            databaseURL: "https://hioperator-prod.firebaseio.com",
            projectId: "hioperator-prod",
            storageBucket: "hioperator-prod.appspot.com",
            messagingSenderId: "329936486573",
            appId: "1:329936486573:web:36a516b8b87ece14"
        },
        dev: {
            apiKey: "AIzaSyAINJ2cSaSN1KWOicR8V5-rwORjGY9Urao",
            authDomain: "hioperator-dev.firebaseapp.com",
            databaseURL: "https://hioperator-dev.firebaseio.com",
            projectId: "hioperator-dev",
            storageBucket: "hioperator-dev.appspot.com",
            messagingSenderId: "720080792237"
        },
        staging: {
            apiKey: "AIzaSyD0VQ6-IKIy3R4YZmvg8X2rcRUArB7XTjY",
            authDomain: "hioperator-staging.firebaseapp.com",
            databaseURL: "https://hioperator-staging.firebaseio.com",
            projectId: "hioperator-staging",
            storageBucket: "hioperator-staging.appspot.com",
            messagingSenderId: "686732170043"
        }
    }
};

export default config;
