import firebase from "firebase/app";
import "firebase/auth";

import gql from "graphql-tag";
import parseError from "./parseError";

const myQuery = gql`
    mutation addMessage {
        addMessage(
            text: "We just emailed you a password reset link. Check your email."
            type: "success"
        ) @client
    }
`;

export default async (_, props, context) => {
    var result = await firebase
        .auth()
        .sendPasswordResetEmail(props.email)
        .then(() => true)
        .catch(function(error) {
            return parseError(error);
        });

    if (result === true) {
        await context.client.mutate({ mutation: myQuery });
        return result;
    } else return result;
};
