import React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

import { Snackbar } from "ui-core";

const Messages = props => {
    return (
        <ReactCSSTransitionGroup
            key="alerts-transition-group"
            transitionName="alerts"
            transitionAppear={false}
            transitionLeave={true}
            transitionEnter={true}
            transitionEnterTimeout={200}
            transitionLeaveTimeout={100}
        >
            {props.localData.messages.length > 0 ? (
                <Snackbar key="snackbar" item={props.localData.messages[0]} />
            ) : (
                ""
            )}
        </ReactCSSTransitionGroup>
    );
};

export default Messages;
