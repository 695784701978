import gql from "graphql-tag";

const query = gql`
    query getMessages {
        messages @client {
            id
            text
            type
        }
    }
`;

export default async (_, { id }, context) => {
    var data = JSON.parse(JSON.stringify(context.cache.readQuery({ query })));

    for (var i = 0; i < data.messages.length; i++) {
        if (id === data.messages[i].id) {
            data.messages.splice(i, 1);
            break;
        }
    }

    return await context.client.writeQuery({ query, data });
};
