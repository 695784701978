export default error => {
    error = String(error).substr(String(error).indexOf(":") + 2, 10000);
    error = String(error);

    console.log("PARSING ERROR");
    console.log(error);

    if (error === "There is no user record corresponding to this identifier. The user may have been deleted.")
        return { id: "email", text: "User does not exist." };
    else if (error === "The password is invalid or the user does not have a password.")
        return { id: "password", text: "Password was incorrect." };
    else if (error === "The user account has been disabled by an administrator.")
        return { id: "email", text: "Your account is disabled. Please contact the system admin." };
    else if (error === "Username/client id combination not found.")
        return { id: "email", text: "Can't find your account. Please double check the spelling of your email." };
    else if (error === "Password does not conform to policy: Password must have symbol characters")
        return { id: "password", text: "Password must have symbol characters" };
    else if (error === "Password does not conform to policy: Password must have uppercase characters")
        return { id: "password", text: "Password must have uppercase characters" };
    else if (error === "Password does not conform to policy: Password must have numeric characters")
        return { id: "password", text: "Password must have numeric characters" };
    else if (error === "Password does not conform to policy: Password must have lowercase characters")
        return { id: "password", text: "Password must have lowercase characters" };
    else if (error === "Too many unsuccessful login attempts. Please try again later.")
        return { id: "email", text: "Too many unsuccessful login attempts. Please try again later." };
    else if (error === "There is no user record corresponding to this identifier. The user may have been deleted.")
        return { id: "email", text: "Can't find user with this email." };
    else if (error === "The email address is badly formatted.")
        return { id: "email", text: "The email address is badly formatted." };
    else if (error === "We have blocked all requests from this device due to unusual activity. Try again later.")
        return {
            id: "email",
            text: "We have blocked all requests from this device due to unusual activity. Try again later."
        };

    console.log(error);
    return "Couldn't find error :(";
};
