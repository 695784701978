import shortid from "shortid";
import gql from "graphql-tag";

const query = gql`
    query getMessages {
        messages @client {
            id
            text
            type
        }
    }
`;

export default async (_, { text, type }, context) => {
    var data = context.cache.readQuery({ query });
    data = JSON.parse(JSON.stringify(data));

    data.messages.unshift({
        id: shortid.generate(),
        type,
        text,
        __typename: "Message"
    });
    return await context.client.writeQuery({ query, data });
};
