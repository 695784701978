export default {
    myId: null,
    myUID: null,
    viewData: null,
    messages: [],
    routeHistory: [],
    loggedIn: false,
    overlay: "",
    overlayDetails: [],
    saver: false,
    currentRoute: "",
    loading: true
};
