import React, { Suspense } from "react";
import { useQuery, gql } from "@apollo/client";

import config from "./config.public";
import { UIContext, Loading } from "ui-core";

import graphql from "./graphql/index";

import * as router from "./functions/router";

var client = graphql();

const authMutation = gql`
    mutation auth {
        auth @client
        monitor @client
    }
`;

client.mutate({ mutation: authMutation });

const rootQuery = gql`
    query getView {
        loading @client
        loggedIn @client
        myUID @client
        currentRoute @client
    }
`;

const App = props => {
    const { data } = useQuery(rootQuery, {
        client
    });

    // Handles loading case
    if (data === undefined || data.loading) {
        return (
            <UIContext.Provider value={config}>
                <Loading />
            </UIContext.Provider>
        );
    }

    // Main rendering
    return (
        <UIContext.Provider value={config}>
            {/* Base layer */}
            <Suspense key="suspense-container" fallback={<Loading />}>
                {router.load({ localData: data })}
            </Suspense>
        </UIContext.Provider>
    );
};

export default App;
