export default [
    {
        value: "discover",
        text: "Discover",
        icon: "fas fa-compass",
        showNav: true,
        default: true
    },
    {
        value: "leads",
        text: "Leads",
        icon: "fas fa-building",
        showNav: true,
        pages: [
            {
                value: ":id",
                text: "Lead",
                redirectToChild: "basic",
                pages: [
                    {
                        value: "basic",
                        text: "Basic",
                        icon: "fas fa-info"
                    },
                    {
                        value: "emails",
                        text: "Emails",
                        icon: "fas fa-envelope",
                        pages: [
                            {
                                value: ":id",
                                text: "Email"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        value: "cycles",
        text: "Email Cycles",
        icon: "fas fa-link",
        showNav: true,
        pages: [
            {
                value: ":id",
                text: "Cycle"
            }
        ]
    },
    {
        value: "configuration",
        text: "Config",
        icon: "fas fa-cogs",
        showNav: true
    },
    {
        value: "settings",
        text: "My Account"
    },
    {
        value: "login",
        text: "Login",
        pages: [
            {
                value: "resetpassword",
                text: "Reset Password"
            }
        ]
    }
];
